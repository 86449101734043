/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-nested-ternary */
import {
  IAddProductImpression,
  ICollectionIdsFilterDTO,
  IGetCategoryProducts,
  IGetInitialData,
  IOldGetInitialData,
  IProduct,
  IProductOption,
  ISorting,
  ReducedOptionSelection,
} from '../types/galleryTypes';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {DataApi} from '../api/DataApi';
import {GetDataQuery, ProductFilters} from '../graphql/queries-schema';
import {
  Experiments,
  FedopsInteraction,
  origin,
  TRACK_EVENT_COLLECTION,
  trackEventMetaData,
  TrackEvents,
} from '../constants';
import {
  AddToCartActionOption,
  APP_DEFINITION_ID,
  BiButtonActionType,
  STORAGE_PAGINATION_KEY,
} from '@wix/wixstores-client-core/dist/es/src/constants';
import {IStoreFrontNavigationContext} from '@wix/wixstores-client-core/dist/es/src/types/site-map';
import {
  actualPrice,
  actualSku,
  hasSubscriptionPlans,
} from '@wix/wixstores-client-core/dist/es/src/productOptions/productUtils';
import {ProductsOptionsService, ProductsVariantInfoMap} from './ProductsOptionsService';
import {ProductActions} from '@wix/wixstores-client-storefront-sdk/dist/es/src/product-actions/ProductActions';
import {IOptionSelectionVariant} from '@wix/wixstores-client-core/dist/es/src/types/product';
import {getProductVariantBySelectionIds} from '@wix/wixstores-client-core/dist/es/src/productVariantCalculator/ProductVariantCalculator';
import {ProductsPriceRangeService, ProductsPriceRangeServiceMap} from './ProductsPriceRangeService';
import _ from 'lodash';

export class ProductsService {
  private readonly dataApi: DataApi;
  public products: IProduct[];
  private readonly productsOptionsService = new ProductsOptionsService();
  private readonly priceRangeService = new ProductsPriceRangeService();
  public totalCount: number;
  public collectionName: string;
  private filters: ProductFilters = null;
  private collectionIds: ICollectionIdsFilterDTO;
  private sorting?: ISorting;
  public hideGallery = false;
  private readonly productActions: ProductActions;

  constructor(
    private readonly siteStore: SiteStore,
    private productsPerPage: number,
    private readonly consumerName: string,
    private withOptions: boolean,
    private readonly withPriceRange: boolean,
    private readonly withBasePrice: boolean,
    private readonly fedopsLogger,
    private readonly shouldUseWarmupData: boolean
  ) {
    this.dataApi = new DataApi(this.siteStore);
    this.productActions = new ProductActions(this.siteStore);
  }

  public updateFiltersAndSort = (filters: ProductFilters, sorting?: ISorting) => {
    this.filters = filters;
    this.sorting = sorting;
  };

  private setProducts(products: IProduct[]) {
    this.products = products;
    this.productsOptionsService.addProducts(this.products);
    this.priceRangeService.addProducts(this.products);
  }

  public async getProducts(
    filters: ProductFilters,
    collectionIds: ICollectionIdsFilterDTO,
    sorting?: ISorting,
    shouldSpecificCollectionQuery?: boolean,
    limit?: number
  ): Promise<IProduct[]> {
    const response = await this.dataApi.getProducts({
      fromIndex: 0,
      toIndex: limit || this.productsPerPage,
      collectionId: shouldSpecificCollectionQuery ? collectionIds.subCategory : collectionIds.mainCategory,
      withOptions: this.withOptions,
      withPriceRange: this.withPriceRange,
      withBasePrice: this.withBasePrice,
      sorting,
      filters: shouldSpecificCollectionQuery ? null : filters,
    });

    this.totalCount = response.totalCount;
    this.setProducts(response.list);
    this.filters = filters;
    this.collectionIds = collectionIds;
    this.sorting = sorting;
    this.sendTrackEvent(0);
    return this.products;
  }

  public handleProductsOptionsChange({productId, selectionIds}: {productId: string; selectionIds: number[]}): void {
    const product = this.products.find((p) => p.id === productId);

    this.productsOptionsService.handleUserInput(product.id, selectionIds);
    this.priceRangeService.handleUserInput(product.id, selectionIds);
  }

  public async oldGetInitialData(
    options: Omit<IOldGetInitialData, 'withPriceRange' | 'withBasePrice'>
  ): Promise<GetDataQuery> {
    const {data} = await this.dataApi.oldGetInitialData({
      ...options,
      limit: this.productsPerPage,
      withPriceRange: this.withPriceRange,
      withBasePrice: this.withBasePrice,
    });
    return this.initState(data);
  }

  public async getInitialData(
    options: Omit<IGetInitialData, 'withOptions' | 'withPriceRange' | 'withBasePrice'>
  ): Promise<GetDataQuery> {
    let limit;
    if (options.limit) {
      limit = options.limit;
    } else {
      limit = this.productsPerPage;
    }

    const optionsWithOverrides = {
      ...options,
      limit,
      withOptions: this.withOptions,
      withPriceRange: this.withPriceRange,
      withBasePrice: this.withBasePrice,
    };

    let data;
    if (this.shouldUseWarmupData) {
      const optionsWithOverridesAsString = Object.keys(optionsWithOverrides)
        .filter((option) => !!optionsWithOverrides[option])
        .map((option) => `${option}=${optionsWithOverrides[option]}`)
        .join('_');
      const key = `gallery_${this.siteStore.getCurrentCurrency()}_${optionsWithOverridesAsString}`;
      const maybeWarmupData = this.siteStore.windowApis.warmupData.get(key);
      if (maybeWarmupData) {
        data = maybeWarmupData;
      } else {
        data = (await this.dataApi.getInitialData(optionsWithOverrides)).data;
        this.siteStore.windowApis.warmupData.set(key, data);
      }
    } else {
      data = (await this.dataApi.getInitialData(optionsWithOverrides)).data;
    }
    return this.initState(data);
  }

  public async getRelatedItems(options: {
    externalId: string;
    productIds: string[];
  }): Promise<Pick<GetDataQuery, 'catalog' | 'appSettings'>> {
    const {data} = await this.dataApi.getRelatedItems({
      ...options,
      withPriceRange: this.withPriceRange,
      withBasePrice: this.withBasePrice,
    });
    const parsedData: Pick<GetDataQuery, 'catalog' | 'appSettings'> = {
      catalog: {
        category: {
          productsWithMetaData: {
            list: data.catalog.relatedProducts,
            totalCount: 16,
          },
          name: '',
          id: '',
        },
      },
      appSettings: data.appSettings,
    };
    this.initState(parsedData);
    return parsedData;
  }

  private initState(data: GetDataQuery): GetDataQuery {
    if (data.catalog.category === null) {
      data.catalog.category = {productsWithMetaData: {list: [], totalCount: 0}, id: '', name: ''};
      this.hideGallery = true;
    }

    this.setProducts(data.catalog.category.productsWithMetaData.list);
    this.collectionName = data.catalog.category.name;
    this.totalCount = data.catalog.category.productsWithMetaData.totalCount;
    this.collectionIds = {mainCategory: data.catalog.category.id};
    this.sendTrackEvent(0);
    return data;
  }

  public sendTrackEvent(fromIndex: number): void {
    if (this.siteStore.isSSR()) {
      return;
    }

    const items: IAddProductImpression[] = this.products.slice(fromIndex).map((p, i) => ({
      id: p.id,
      name: p.name,
      list: this.consumerName,
      category: TRACK_EVENT_COLLECTION,
      position: i + fromIndex,
      price: p.comparePrice || p.price,
      currency: this.siteStore.currency,
      dimension3: p.isInStock ? 'in stock' : 'out of stock',
    }));

    this.siteStore.windowApis.trackEvent('AddProductImpression', {
      appDefId: APP_DEFINITION_ID,
      contents: items,
      origin: 'Stores',
    });
  }

  public hasMoreProductsToLoad(): boolean {
    return this.products.length < this.totalCount;
  }

  public setProductsPerPage(productsPerPage: number): void {
    this.productsPerPage = productsPerPage;
  }

  public getProductPerPage(): number {
    return this.productsPerPage;
  }

  public setWithOptions(withOptions: boolean): void {
    this.withOptions = withOptions;
  }

  private removeNotUIVisibleProducts(visibleProducts: number) {
    if (visibleProducts !== this.products.length) {
      this.setProducts(this.products.slice(0, visibleProducts));
    }
  }

  public getProduct(id: string): IProduct {
    return this.products.filter((p) => p.id === id)[0];
  }

  public async getCategoryProducts({compId, limit, offset}: IGetCategoryProducts): Promise<void> {
    const {data} = await this.dataApi.getCategoryProducts({
      compId,
      limit,
      offset,
      withOptions: false,
      withPriceRange: this.withPriceRange,
      withBasePrice: this.withBasePrice,
    });
    const retrievedProducts = data.catalog.category.productsWithMetaData.list;
    this.products.splice(offset, retrievedProducts.length, ...retrievedProducts);

    if (this.siteStore.experiments.enabled(Experiments.SliderGalleryAddToCartWithManyProducts)) {
      this.setProducts(this.products);
    }
  }

  public async loadMoreProducts(visibleProducts: number, shouldSpecificCollectionQuery?: boolean): Promise<IProduct[]> {
    this.removeNotUIVisibleProducts(visibleProducts);
    const apiResponse = await this.dataApi.getProducts({
      fromIndex: visibleProducts,
      toIndex: visibleProducts + this.productsPerPage,
      withOptions: this.withOptions,
      withPriceRange: this.withPriceRange,
      withBasePrice: this.withBasePrice,
      sorting: this.sorting,
      filters: shouldSpecificCollectionQuery ? null : this.filters,
      collectionId: shouldSpecificCollectionQuery ? this.collectionIds.subCategory : this.collectionIds.mainCategory,
    });
    if (apiResponse.list.length === 0) {
      return null;
    }
    this.setProducts(this.products.concat([...apiResponse.list]));
    this.sendTrackEvent(visibleProducts);
    return this.products;
  }

  public async loadProducts(from: number, to: number, shouldSpecificCollectionQuery?: boolean): Promise<IProduct[]> {
    const apiResponse = await this.dataApi.getProducts({
      fromIndex: from,
      toIndex: to,
      withOptions: this.withOptions,
      withPriceRange: this.withPriceRange,
      withBasePrice: this.withBasePrice,
      sorting: this.sorting,
      filters: shouldSpecificCollectionQuery ? null : this.filters,
      collectionId: shouldSpecificCollectionQuery ? this.collectionIds.subCategory : this.collectionIds.mainCategory,
    });
    this.setProducts([...apiResponse.list]);
    return this.products;
  }

  public async sortProducts(sorting: ISorting, shouldSpecificCollectionQuery: boolean): Promise<IProduct[]> {
    await this.getProducts(this.filters, this.collectionIds, sorting, shouldSpecificCollectionQuery);
    return this.products;
  }

  public async filterProducts({
    filters,
    collectionIds,
    shouldSpecificCollectionQuery,
    limit,
  }: {
    filters: ProductFilters;
    collectionIds: ICollectionIdsFilterDTO;
    shouldSpecificCollectionQuery: boolean;
    limit?: number;
  }): Promise<IProduct[]> {
    this.setProducts(
      await this.getProducts(filters, collectionIds, this.sorting, shouldSpecificCollectionQuery, limit)
    );
    return this.products;
  }

  public getMainCollectionId(): string {
    return this.collectionIds.mainCategory;
  }

  public storeNavigation(pageId: string): void {
    const paginationMap = this.products.map((p) => p.urlPart);
    const history: IStoreFrontNavigationContext = {
      pageId,
      paginationMap,
    };
    this.siteStore.storage.local.setItem(STORAGE_PAGINATION_KEY, JSON.stringify(history));
  }

  public quickViewProduct(
    productId: string,
    index: number,
    compId?: string,
    externalId?: string,
    selectionIds?: number[],
    quantity?: number
  ): Promise<any> {
    const product = this.getProduct(productId);
    this.siteStore.biLogger.clickedOnProductQuickViewSf({
      productId,
      hasRibbon: !!product.ribbon,
      hasOptions: this.hasOptions(product),
      index,
    });
    this.sendClickTrackEvent(product, index);
    return this.productActions.quickViewProduct({
      origin: this.consumerName.split(' ').join('-'),
      urlPart: product.urlPart,
      compId,
      externalId,
      selectionIds,
      quantity,
      title: product.name,
    });
  }

  public sendClickTrackEvent(product: IProduct, index: number): void {
    this.siteStore.windowApis.trackEvent('ClickProduct', {
      appDefId: APP_DEFINITION_ID,
      id: product.id,
      origin: 'Stores',
      name: product.name,
      list: 'Grid Gallery',
      category: TRACK_EVENT_COLLECTION,
      position: index,
      price: product.comparePrice || product.price,
      currency: this.siteStore.currency,
      type: product.productType,
      sku: product.sku,
    });
  }

  private readonly hasOptions = (product: IProduct) => !!product.options.length;

  private readonly hasSubscriptionPlans = (product: IProduct) => {
    return hasSubscriptionPlans(product);
  };

  private readonly flatAndEnrichSelections = (productOptions: IProductOption[]): ReducedOptionSelection[] =>
    _.flatten(
      productOptions.map(({selections, key: optionKey, id: optionId}) =>
        selections.map<ReducedOptionSelection>(({key: selectionKey, id: selectionId}) => ({
          selectionKey,
          selectionId,
          optionId,
          optionKey,
        }))
      )
    );
  private readonly calculateVariantOptions = (selectionIds: number[], product: IProduct): Record<string, string> => {
    const selectionSet = selectionIds.reduce((set, id) => set.add(id), new Set());
    return this.flatAndEnrichSelections(product.options)
      .filter((enrichedSelection) => selectionSet.has(enrichedSelection.selectionId))
      .reduce((acc, enrichedSelection) => {
        acc[enrichedSelection.optionKey] = enrichedSelection.selectionKey;
        return acc;
      }, {});
  };

  public async addToCart({
    productId,
    index,
    quantity,
    compId,
    externalId,
    action,
  }: {
    productId: string;
    index: number;
    quantity: number;
    compId: string;
    externalId: string;
    action: AddToCartActionOption;
  }): Promise<any> {
    const product = this.getProduct(productId);
    const trackParams = this.getTrackEventParams(product);
    const variantSelectionIds = this.productsOptionsService.getVariantSelectionIds(productId);
    const shouldOpenQuickView =
      !this.productsOptionsService.canAddToCart(productId) || this.hasSubscriptionPlans(product);

    if (shouldOpenQuickView) {
      this.siteStore.biLogger.clickAddToCartWithOptionsSf({
        appName: 'galleryApp',
        origin,
        hasOptions: true,
        productId,
        productType: product.productType,
        navigationClick: this.siteStore.isMobile() ? 'product-page' : 'quick-view',
      });
      return this.quickViewProduct(productId, index, compId, externalId, variantSelectionIds, quantity);
    }

    const variantOptions = this.calculateVariantOptions(variantSelectionIds, product);
    const shouldNavigateToCart = this.siteStore.cartActions.shouldNavigateToCart();
    const variant = getProductVariantBySelectionIds({product, variantSelectionIds});

    this.siteStore.windowApis.trackEvent(TrackEvents.ViewContent, trackParams);
    this.fedopsLogger.interactionStarted(FedopsInteraction.AddToCart);

    return this.siteStore.cartActions.addToCart(
      productId,
      variantSelectionIds,
      quantity,
      [],
      action,
      origin,
      () => this.fedopsLogger.interactionEnded(FedopsInteraction.AddToCart),
      {
        ...trackParams,
        buttonType: BiButtonActionType.AddToCart,
        appName: 'galleryApp',
        productType: product.productType as any,
        isNavigateCart: shouldNavigateToCart,
        navigationClick:
          /* istanbul ignore next: need to fix */
          action === AddToCartActionOption.MINI_CART && !shouldNavigateToCart
            ? 'mini-cart'
            : action === AddToCartActionOption.CART || (shouldNavigateToCart && action !== AddToCartActionOption.NONE)
            ? 'cart'
            : 'none',
      },
      variant?.id,
      undefined,
      variantOptions
    );
  }

  private getTrackEventParams(product: IProduct) {
    const variantSelectionIds = this.productsOptionsService.getVariantSelectionIds(product.id);
    const variant = getProductVariantBySelectionIds({product, variantSelectionIds}) as IOptionSelectionVariant;
    return {
      ...trackEventMetaData,
      id: product.id,
      name: product.name,
      price: actualPrice(product, variant),
      currency: this.siteStore.currency,
      sku: actualSku(product, variant),
      type: product.productType,
    };
  }

  public clearSelections() {
    this.productsOptionsService.clearSelections();
    this.priceRangeService.clearSelections();
  }

  public getVariantInfoMap(): ProductsVariantInfoMap {
    return this.productsOptionsService.getVariantInfoMap();
  }

  public get productPriceRangeMap(): ProductsPriceRangeServiceMap {
    return this.withPriceRange ? this.priceRangeService.getProductPriceRangeMap() : {};
  }
}
